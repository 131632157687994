<template>
  <v-card v-if="$can('material_bill_list', 'material_bill')" id="holiday-common">
    <v-form ref="searchForm" @submit.prevent="toSearch()">
      <v-card-title>
        <v-row>
          <v-col md="12">
            <p class="text-h6">领料管理</p>
          </v-col>
          <v-col class="d-flex flex-row-reverse pb-0" md="12">
            <!-- 新增出入库类型， 靠右显示 -->
            <div v-if="$can('material_bill_save', 'material_bill')" class="d-flex align-center">
              <v-btn color="primary" @click="editItem()"> 新建领料单 </v-btn>
            </div>

            <div class="d-flex align-center">
              <v-btn class="me-3" @click="resetSearch()">
                <span>重置</span>
              </v-btn>
            </div>

            <div class="d-flex align-center">
              <v-btn color="secondary" class="me-3" type="submit">
                <v-icon size="18" class="me-1">
                  {{ icons.mdiMagnify }}
                </v-icon>
                <span>查询</span>
              </v-btn>
            </div>

            <div class="d-flex align-center pt-6 mr-5 pb-0">
              <v-text-field v-model="search.id" outlined dense label="请输入查询内容"></v-text-field>
            </div>

            <div class="d-flex align-center pt-6 pb-0">
              <DatePick v-model="search.endTime" :hide="false" label="结束时间"></DatePick>
            </div>
            <div class="d-flex align-center">
              <span class="text-subtitle-2">至</span>
            </div>

            <div class="d-flex align-center pt-6 pb-0">
              <DatePick v-model="search.startTime" :hide="false" label="开始时间"></DatePick>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-form>
    <v-card-text>
      <v-row>
        <v-col md="12">
          <v-tabs v-model="currentTab" style="box-shadow: none !important" @change="reloadTab">
            <v-tab>待审核({{ billCount.waitConfirmCount }})</v-tab>
            <v-tab>待处理({{ billCount.waitManageCount}})</v-tab>
            <v-tab>已完成({{ billCount.confirmedCount }})</v-tab>
            <v-tab>全部({{ billCount.allCount }})</v-tab>
          </v-tabs>
        </v-col>
        <v-col>
          <v-data-table
            :footer-props="{
              itemsPerPageAllText: '全部',
              itemsPerPageText: '每页显示 ',
              showCurrentPage: true,
            }"
            disable-filtering
            disable-sort
            :headers="getHeader()"
            :items="materialBills"
            :page="pages.page"
            :items-per-page="pages.itemsPerPage"
            :server-items-length="pages.serverItemsLength"
            no-data-text="无数据"
            @pagination="loadPage"
          >
          <!-- 表格列表插槽 -->
            <template #[`item.status`]="{ item }">
              <v-chip :color="getOrderStatusColor(item.status)" small class="font-weight-semibold text-capitalize">
                {{ getOrderStatusByValue(item.status) }}
              </v-chip>
            </template>

            <template #[`item.takeUserName`]="{ item }">
              <span>{{item.takeUserName ? item.takeUserName : item.sysTakeUserName}}</span>
            </template>
            
            <template #[`item.printCount`]="{ item }">
              <template v-if="item.printCount">
                <v-badge :content="item.printCount" inline color="info">
                  <v-chip color="success" small class="font-weight-semibold text-capitalize"> 已打印 </v-chip>
                </v-badge>
              </template>
              <template v-else>
                <v-chip color="danger" small class="font-weight-semibold text-capitalize"> 未打印 </v-chip>
              </template>
            </template>

            <!-- action -->
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="$can('material_bill_detail', 'material_bill')"
                text
                dense
                x-small
                color="primary"
                @click="showDetail(item.id)"
              >
                查看详情
              </v-btn>
              <span v-if="$can('material_bill_toggle_status', 'material_bill')">
                <!-- 0是待审核、1是已完成 、2是取消 、3是待处理-->
                <v-btn v-if="item.status === 0" text dense x-small color="primary" @click="toggleStatus(item.id, 3)">
                  通过
                </v-btn>
                <v-btn v-if="item.status === 3" text dense x-small color="primary" @click="toggleStatus(item.id, 1)">
                  确认领料
                </v-btn>
                <v-btn v-if="item.status === 0 || item.status === 3" text dense x-small color="primary" @click="toggleStatus(item.id, 2)">
                  取消领料
                </v-btn>
              </span>
            </template>
          </v-data-table>
          <div style="position: absolute; right: 260px; bottom: 25px">
            <div style="display: flex; flex-wrap: wrap">
              <span style="padding: 7px; font-size: 13px">总页数：{{ pages.pages }}</span>
              <span style="padding: 7px; font-size: 13px">前往</span>
              <div style="width: 45px">
                <v-text-field
                  @change="changePage"
                  v-model="toPage"
                  type="number"
                  hide-details
                  dense
                  min="1"
                ></v-text-field>
              </div>
              <span style="padding: 7px; font-size: 13px">页</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '85%' : '100%'"
      app
    >
      <v-card
        v-if="$can('material_bill_save_data', 'material_bill') && $can('material_bill_save', 'material_bill')"
        height="100%"
      >
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">新建领料单</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-5" :disabled="commitFlag" :loading="commitFlag" @click="handleFormSubmit()">
            保存
          </v-btn>
          <v-btn icon small @click="editDialog = false">
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          v-if="$can('material_bill_save_data', 'material_bill')"
          style="background-color: #f4f5fa; height: 90%; padding-top: 20px"
          class="overflow-auto"
        >
          <v-card tile>
            <v-card-title>
              <p class="text-h6">基本信息</p>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field v-model="baseInfo.takeUserName" label="*领料客户" dense outlined clearable></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-select
                    v-model="baseInfo.userId"
                    :items="users"
                    label="*领料人"
                    item-text="nickName"
                    item-value="id"
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="baseInfo.remark" label="*领料原因" outlined clearable></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-10">
            <v-card-title>
              <p class="text-h6">领料详情</p>
            </v-card-title>
            <v-card-text>
              <v-form ref="searchSkuForm">
                <v-row>
                  <v-col class="d-flex flex-row-reverse pb-0">
                    <div class="d-flex align-center">
                      <v-btn class="me-3" @click="resetSkuSearch()">
                        <span>重置</span>
                      </v-btn>
                    </div>

                    <div class="d-flex align-center">
                      <v-btn color="secondary" class="me-3" @click="toSkuSearch()">
                        <v-icon size="18" class="me-1">
                          {{ icons.mdiMagnify }}
                        </v-icon>
                        <span>查询</span>
                      </v-btn>
                    </div>

                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-text-field v-model="skuSearch.name" outlined dense label="搜索商品"></v-text-field>
                    </div>

                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        id="id"
                        v-model="skuSearch.saleType"
                        outlined
                        dense
                        label="销售类型"
                        item-text="name"
                        item-value="value"
                        :items="saleTypes"
                      ></v-select>
                    </div>
                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        id="id"
                        v-model="skuSearch.categoryId"
                        outlined
                        dense
                        label="所属分类"
                        item-text="name"
                        item-value="id"
                        :items="categorys"
                      ></v-select>
                    </div>
                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        id="id"
                        v-model="skuSearch.warehouseId"
                        outlined
                        dense
                        label="所属仓库"
                        item-text="name"
                        item-value="id"
                        :items="warehouses"
                      ></v-select>
                    </div>
                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        v-model="skuSearch.selectOrderType"
                        :items="orderTypes"
                        label="排序方式"
                        dense
                        outlined
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col md="12">
                  <v-tabs v-model="skuTab">
                    <v-tab>本次出库 ({{ recordQuantity }})</v-tab>
                    <v-tab>全部({{ allQuantity }})</v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-tabs-items v-model="skuTab">
                    <v-tab-item>
                      <v-data-table
                        :footer-props="{
                          itemsPerPageAllText: '全部',
                          itemsPerPageText: '每页显示 ',
                          showCurrentPage: true,
                        }"
                        disable-filtering
                        disable-sort
                        :headers="getSkuHeader()"
                        no-data-text="无数据"
                        :items="records"
                      >
                        <template #[`item.productName`]="{ item }">
                          <v-row>
                            <v-col cols="auto">
                              <v-avatar size="33" @click.stop="previewImg(item.skuProductImg)">
                                <img :src="item.skuProductImg || require('@/assets/images/white.jpeg')" alt="John" />
                              </v-avatar>
                            </v-col>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.productName }} </span><br />
                              <span v-if="!item.isSingle" class="text-caption">{{ item.title }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <template #[`item.categoryName`]="{ item }">
                          <v-row>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.categoryName }} </span><br />
                              <span class="text-caption">{{ item.warehouseName }}</span>
                            </v-col>
                          </v-row>
                        </template>

                        <template #[`item.stockNum`]="{ item }">
                          <span>{{ convertToBoxAndRemainder(item.stockNum, item) }}</span>
                        </template>
                        <template #[`item.totalQuantity`]="{ item }">
                          <span style="color: red">{{
                            item.totalQuantity ? convertToBoxAndRemainder(item.totalQuantity, item) : ''
                          }}</span>
                        </template>

                        <template #[`item.unit`]="{ item }">
                          <v-select
                            id="unitId"
                            v-model="item.unitId"
                            dense
                            disabled
                            style="width: 70px"
                            hide-details
                            item-text="unitName"
                            item-value="id"
                            :items="item.unitConversion"
                          ></v-select>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-data-table
                        :footer-props="{
                          itemsPerPageAllText: '全部',
                          itemsPerPageText: '每页显示 ',
                          showCurrentPage: true,
                        }"
                        disable-filtering
                        disable-sort
                        :headers="getSkuHeader()"
                        :items="skus"
                        :page="skuPages.page"
                        :items-per-page="skuPages.itemsPerPage"
                        :server-items-length="skuPages.serverItemsLength"
                        no-data-text="无数据"
                        @pagination="loadSkuPage"
                      >
                        <template #[`item.productName`]="{ item }">
                          <v-row>
                            <v-col cols="auto">
                              <v-avatar size="33" @click.stop="previewImg(item.skuProductImg)">
                                <img :src="item.skuProductImg || require('@/assets/images/white.jpeg')" alt="John" />
                              </v-avatar>
                            </v-col>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.productName }} </span><br />
                              <span v-if="!item.isSingle" class="text-caption">{{ item.title }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <template #[`item.categoryName`]="{ item }">
                          <v-row>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.categoryName }} </span><br />
                              <span class="text-caption">{{ item.warehouseName }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <template #[`item.quantity`]="{ item }">
                          <v-text-field
                            v-model="item.quantity"
                            style="width: 150px"
                            type="number"
                            placeholder="输入数量"
                            dense
                            hide-details
                            min="0"
                            @change="addRecord(item)"
                          ></v-text-field>
                        </template>
                        <template #[`item.stockNum`]="{ item }">
                          <span>{{ convertToBoxAndRemainder(item.stockNum, item) }}</span>
                        </template>
                        <template #[`item.totalQuantity`]="{ item }">
                          <span style="color: red">{{
                            item.totalQuantity ? convertToBoxAndRemainder(item.totalQuantity, item) : ''
                          }}</span>
                        </template>
                        <template #[`item.unit`]="{ item }">
                          <v-select
                            id="unitId"
                            v-model="item.unitId"
                            dense
                            style="width: 70px"
                            hide-details
                            item-text="unitName"
                            item-value="id"
                            :items="item.unitConversion"
                            @change="changeUnit(item)"
                          ></v-select>
                        </template>
                      </v-data-table>
                      <div style="position: absolute; right: 260px; bottom: 7px">
                        <div style="display: flex; flex-wrap: wrap">
                          <span style="padding: 7px; font-size: 13px">总页数：{{ skuPages.pages }}</span>
                          <span style="padding: 7px; font-size: 13px">前往</span>
                          <div style="width: 45px">
                            <v-text-field
                              @change="changeSkuPage"
                              v-model="toSkuPage"
                              type="number"
                              hide-details
                              dense
                            ></v-text-field>
                          </div>
                          <span style="padding: 7px; font-size: 13px">页</span>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-dialog v-model="previewImgDialog" max-width="500">
        <v-card>
          <v-card-text>
            <v-img :src="previewImgUrl || require('@/assets/images/white.jpeg')" max-width="500"></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="recordDetailDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '70%' : '100%'"
      app
    >
      <v-card v-if="$can('material_bill_detail', 'material_bill')" height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">领料单</span>

          <v-btn icon small :disabled="suffixId == null" @click="showDetail(suffixId)">
            <v-icon size="22">
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </v-btn>
          &nbsp;&nbsp;
          <v-btn icon small :disabled="prefixId == null" @click="showDetail(prefixId)">
            <v-icon size="22">
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-btn>
          <!-- 分割元素，推向两边 -->
          <v-spacer></v-spacer>
          <v-btn
            v-if="recordDetail.baseInfo && recordDetail.baseInfo.detail && (recordDetail.baseInfo.detail.status == 1 || recordDetail.baseInfo.detail.status == 3)"
              color="primary"
              class="mr-5"
              @click="toPrint"
            >
              打印
          </v-btn>
        </div>

        <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
          <BillDetail
            :key="detail.key"
            :detail="detail"
            :log="$can('material_bill_log', 'material_bill')"
            @getNextAndPreSku="getNextAndPreSku"
            @getDetail="getDetail"
            @showLog="showStockLog"
          />
        </v-card-text>
      </v-card>
      <v-dialog v-model="logDialog" scrollable max-width="85%">
        <v-card>
          <v-card-title>
            <p class="text-h6">库存日志</p>
          </v-card-title>
          <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
            <Log :key="skuStock.key" :sku-stock="skuStock" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeft,
  mdiArrowRight,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import MaterialBillApi from '@/api/product/materialBill'
import Log from '@/components/Log'
import BillDetail from '@/components/BillDetail'
import DatePick from '@/components/DatePicker'

export default {
  name: 'MemberLevel',
  components: {
    Log,
    BillDetail,
    DatePick,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiArrowLeft,
        mdiArrowRight,
        mdiChevronLeft,
        mdiChevronRight,
      },
      editDialog: false,
      deleteDialog: false,
      loadings: {
        submitLoading: false,
      },
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      skuPages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      search: {},
      baseInfo: {},
      skuSearch: {
        selectOrderType: 0,
      },
      categorys: [],
      saleTypes: [
        {
          name: '对外销售',
          value: 'OUTSIDE_SALE',
        },
        {
          name: '对内销售',
          value: 'INSIDE_SALE',
        },
      ],
      skuTab: 1,
      recordQuantity: 0,
      allQuantity: 0,
      skus: [],
      records: [],
      recordDetailDialog: false,
      detailPages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      warehouses: [],
      billCount: {},
      currentTab: 0,
      materialBills: [],
      status: 0,
      statusList: [
        { text: '待审核', color: 'danger', value: 0 },
        { text: '待处理', color: 'warning', value: 3 },
        { text: '已完成', color: 'success', value: 1 },
        { text: '已拒绝', color: '', value: 2 },
      ],
      users: [],
      commitFlag: false,
      skuStock: {
        key: 0,
      },
      logDialog: false,
      prefixId: null,
      suffixId: null,
      detail: {
        key: 0,
        name: '',
      },
      previewImgUrl: '',
      previewImgDialog: false,
      recordDetail: {},
      toPage: null,
      toSkuPage: null,
      orderTypes: [
        {
          text: '按商品分类排序',
          value: 0,
        },
        {
          text: '按库存数量升序',
          value: 1,
        },
        {
          text: '按库存数量降序',
          value: 2,
        },
      ],
    }
  },
  methods: {
    reloadTab() {
      if (this.currentTab === 3) {
        this.status = undefined
      } else if (this.currentTab === 1) {
        this.status = 3
      } else if (this.currentTab === 0) {
        this.status = 0
      } else if (this.currentTab === 2) {
        this.status = 1
      }
      this.toSearch()
    },
    getHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'id' },
        { text: '单据编号', sortable: false, value: 'materialNumber' },
        { text: '领料人(客户)', value: 'takeUserName' },
        { text: '申请时间', value: 'createdTime' },
        // { text: '审核人', value: 'handleUserName' },
        { text: '审核时间', value: 'handledTime' },
        { text: '领料原因', value: 'remark' },
        { text: '状态', value: 'status' },
      ]

      if (this.status == 1 || this.status == 3) {
        baseHeader.push({ text: '打印状态', value: 'printCount' })
      }

      if (
        this.$can('material_bill_toggle_status', 'material_bill') ||
        this.$can('material_bill_detail', 'material_bill')
      ) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.toPage = null
      this.pages.page = 1
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.search.startTime = ''
      this.search.endTime = ''
      this.toSearch()
    },
    changePage(modifyPage) {
      if (modifyPage > this.pages.pages) {
        this.$toast.error('超过最大总页数')
        return
      }
      this.pages.page = parseInt(modifyPage)
    },
    loadPage({ page, itemsPerPage }) {
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        startTime,
        endTime,
        status: this.status,
        materialId: this.search.id,
      }
      MaterialBillApi.getPages(searchData).then(response => {
        const { pages, countDTO } = response.data.data
        this.billCount = countDTO
        this.materialBills = pages.records
        this.pages.pages = pages.pages
        if (pages.size === -1) {
          this.pages.serverItemsLength = this.materialBills.length
        } else {
          this.pages.serverItemsLength = pages.total
        }
      })
    },
    getSkuHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'skuId' },
        { text: '商品名称', value: 'productName' },
        { text: '所属分类/仓库', value: 'categoryName' },
        { text: '包装规格', value: 'unit' },
        { text: '总库存', value: 'stockNum' },
        { text: '领料数量', value: 'quantity' },
        { text: '出库数量', value: 'totalQuantity' },
      ]
      if (this.recordDetailDialog && this.$can('material_bill_log', 'material_bill')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSkuSearch() {
      this.toSkuPage = null
      this.skuPages.page = 1
      this.loadSkuPage({ page: 1, itemsPerPage: this.skuPages.itemsPerPage })
    },
    resetSkuSearch() {
      this.$refs.searchSkuForm.reset()
      this.$nextTick(() => {
        this.skuSearch.selectOrderType = 0
      })
      this.toSkuSearch()
    },
    changeSkuPage(modifyPage) {
      if (modifyPage > this.skuPages.pages) {
        this.$toast.error('超过最大总页数')
        return
      }
      this.skuPages.page = parseInt(modifyPage)
    },
    loadSkuPage({ page, itemsPerPage }) {
      let rows = itemsPerPage
      if (!rows) {
        rows = this.skuPages.itemsPerPage
      }
      const searchData = {
        rows,
        page,
        categoryId: this.skuSearch.categoryId,
        warehouseId: this.skuSearch.warehouseId,
        name: this.skuSearch.name,
        orderType: this.skuSearch.selectOrderType || 0,
      }
      if (this.skuSearch.saleType) {
        const type = this.skuSearch.saleType
        if (type === 'OUTSIDE_SALE') {
          searchData.isOutsideSale = true
        } else {
          searchData.isInsideSale = true
        }
      }
      if (this.$can('material_bill_save_data', 'material_bill')) {
        MaterialBillApi.loadData(searchData).then(response => {
          const { data, searchVo } = response.data.data
          this.skus = data.records
          this.skus.forEach(sku => {
            sku.unitId = sku.unitConversion[0].id
          })
          if (this.records.length > 0) {
            // 根据skuId判断我这个records是否在data.records中，如果在就需要替换里面的对象
            this.records.forEach(record => {
              const index = data.records.findIndex(item => item.skuId === record.skuId)
              if (index !== -1) {
                data.records[index] = record
                data.records[index].unitId = record.unitId
              }
            })
          }

          if (data.size === -1) {
            this.skuPages.serverItemsLength = this.skus.length
          } else {
            this.skuPages.serverItemsLength = data.total
          }
          this.allQuantity = data.total
          this.skuPages.pages = data.pages
          const { categorys, warehouses, users } = searchVo

          categorys.forEach(category => {
            if (category.parentId !== 0) {
              category.name = `\xa0\xa0${category.name}`
            }
          })
          this.categorys = categorys
          this.warehouses = warehouses
          this.users = users
        })
      }
    },
    addRecord(item) {
      if (item.quantity === null || item.quantity === undefined || item.quantity === 0 || item.quantity === '') {
        // 从records 中移除
        this.records = this.records.filter(record => record.skuId !== item.skuId)
        this.recordQuantity -= 1
      } else {
        const { unitId } = item
        const { unitConversion } = item
        const selectedUnit = unitConversion.find(unit => unit.id === unitId)
        item.totalQuantity =
          parseInt(item.stockNum, 0) - parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)

        const record = this.records.find(record => record.skuId === item.skuId)
        if (record) {
          record.quantity = item.quantity
        } else {
          this.records.push(item)
          this.recordQuantity += 1
        }
      }
    },
    editItem() {
      // this.loadData()
      this.skuTab = 1
      this.recordQuantity = 0
      this.records = []
      this.skus = []
      this.baseInfo.remark = ''
      this.baseInfo.takeUserName = ''
      this.baseInfo.userId = undefined
      this.skuPages.page = 1
      this.toSkuPage = null
      this.$refs.searchSkuForm.reset()
      this.$nextTick(() => {
        this.skuSearch.selectOrderType = 0
      })
      this.commitFlag = false
      this.editDialog = true
    },
    handleFormSubmit() {
      if (this.records.length === 0) {
        this.$toast.warning('请添加领料记录')

        return
      }

      if (this.baseInfo.userId === null || this.baseInfo.userId === undefined) {
        this.$toast.warning('请选择领料人')

        return
      }

      if (this.baseInfo.takeUserName === null || this.baseInfo.takeUserName.trim().length < 1) {
        this.$toast.warning('请填写领料客户')

        return
      }

      if (this.baseInfo.remark === null || this.baseInfo.remark.trim().length < 1) {
        this.$toast.warning('请填写领料原因')

        return
      }

      if (this.skuTab !== 0) {
        this.$toast.warning('请先确认领料记录，在进行保存')
        this.skuTab = 0

        return
      }

      if (this.commitFlag) {
        return
      }

      const saveRecords = []
      this.records.forEach(record => {
        saveRecords.push({
          skuId: record.skuId,
          quantity: record.quantity,
          unitId: record.unitId,
          unitConversion: record.unitConversion,
        })
      })
      const saveData = {
        takeUserId: this.baseInfo.userId,
        takeUserName: this.baseInfo.takeUserName,
        remark: this.baseInfo.remark,
        records: saveRecords,
      }
      this.commitFlag = true
      MaterialBillApi.createMaterialBill(saveData).then(res => {
        this.$toast.success('添加成功')
        this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
        this.commitFlag = false
        this.editDialog = false
      }).catch(e => {
        this.commitFlag = false
        console.log(e)
      })
    },
    showDetail(id) {
      this.detail.id = id
      this.detail.status = this.status
      this.detail.searchValue = this.search.id
      this.detail.key += 1
      this.recordDetailDialog = true
    },
    showStockLog(skuId) {
      this.skuStock.skuId = skuId
      this.skuStock.key += 1
      this.logDialog = true
    },
    getOrderStatusByValue(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.text : ''
    },
    getOrderStatusColor(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.color : ''
    },
    toggleStatus(materialBillId, status) {
      const userDataStr = localStorage.getItem('userData')
      const userData = JSON.parse(userDataStr)
      const { id } = userData
      MaterialBillApi.toggleMaterialBillStatus(materialBillId, status, id).then(res => {
        this.$toast.success('操作成功')

        this.loadPage(this.pages)
      })
    },
    getNextAndPreSku(item) {
      this.prefixId = item.prefixId
      this.suffixId = item.suffixId
    },
    previewImg(url) {
      this.previewImgUrl = url
      this.previewImgDialog = true
    },
    convertToBoxAndRemainder(totalQuantity, item) {
      const { unitId } = item
      const { unitConversion } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)

      // 如果isBase为true，则直接返回基准单位的数量
      if (selectedUnit.isBase) {
        return `基准单位：${totalQuantity}${selectedUnit.unitName}`
      }

      // 计算整箱数和尾数
      const boxes = Math.floor(totalQuantity / selectedUnit.unitQuantity)
      const remainder = totalQuantity % selectedUnit.unitQuantity

      // 构建结果字符串
      let result = ''

      // 如果没有整箱，则只显示尾数
      if (boxes === 0) {
        result = `0箱+${remainder}个尾数`
      } else {
        // 否则显示箱数和尾数
        result = `${boxes}箱`

        // 如果有尾数，则添加尾数部分
        if (remainder > 0) {
          result += `+${remainder}个尾数`
        }
      }

      return result
    },
    changeUnit(item) {
      const { unitId } = item
      const { unitConversion } = item
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)
      if (this.model === 0) {
        item.totalQuantity =
          parseInt(item.stockNum, 0) - parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
      } else {
        item.totalQuantity =
          parseInt(item.stockNum, 0) + parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
      }
      const record = this.records.find(record => record.skuId === item.skuId)
      if (record) {
        record.quantity = item.quantity
      } else {
        this.records.push(item)
        this.recordQuantity += 1
      }
    },
    getDetail(item) {
      this.recordDetail = item
    },
    toPrint() {
      // this.$router.push({ name: 'print' }, () => window.open(this.$route.href, '_blank'))

      const routeUrl = this.$router.resolve({
        path: '/print',

        query: { id: 96 },
      })

       // 执行跳转打印界面后，一秒后再执行请求刷新数据接口，确保数据为最新数据状态
       setTimeout(() => {
        this.toSearch()
        this.recordDetailDialog = false
      }, 1000)


      this.recordDetail.baseInfo.isIn = false
      localStorage.setItem('printData', JSON.stringify(this.recordDetail))

      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>

<style scoped>
</style>
